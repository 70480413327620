import * as yup from "yup";

const requiredFieldMessage = "Campo requerido";

export default yup.object().shape({
  creId: yup.string().required(requiredFieldMessage),
  name: yup.string().required(requiredFieldMessage),
  streetAddress: yup.string().required(requiredFieldMessage),
  postalCode: yup.string().required(requiredFieldMessage),
  municipality: yup.string().required(requiredFieldMessage),
  state: yup.string().required(requiredFieldMessage),
  settlement: yup.string().required(requiredFieldMessage),
});
