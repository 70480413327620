import {
  House as HouseIcon,
  Receipt as ReceiptIcon,
  Lock,
  UsersThree,
  Archive,
} from "phosphor-react";
import { Navigate, Route, Routes } from "react-router-dom";
import { SidebarLink } from "./components/Sidebar/interfaces/sidebar-link";
import Sidebar from "./components/Sidebar/Sidebar";
import { useAppDispatch, useAppSelector } from "./hooks/redux";
import Login from "./pages/Login/Login";
import styles from "./App.module.css";
import { ThemeProvider } from "styled-components";
import GlobalStyles, { ThemeType } from "./styles/Global";
import { logout } from "./redux/states/auth/auth-actions";
import Records2 from "./pages/Records2/Records2";
import NewRecord from "./pages/Records2/NewRecord";
import EditRecord from "./pages/Records2/EditRecords";
import Users from "./pages/Users/Users";
import NewUser from "./pages/Users/NewUser";
import Home from "./pages/Home/Home";
import { ToastContainer } from "react-toastify";
import EditUser from "./pages/Users/EditUser";
import ViewCase from "./pages/Cases/ViewCase";
import NewCase from "./pages/Cases/NewCase";
import Cases from "./pages/Cases/Cases";
import { UserRole } from "./interfaces/user.model";
function App() {
  // const dispatch = useAppDispatch();

  // useEffect(() => {
  //   dispatch(removeError());
  // }, [dispatch]);

  const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);
  const userRole = useAppSelector((state) => state.auth.role);

  const links: SidebarLink[] = [
    {
      name: "Inicio",
      path: "/home",
      icon: <HouseIcon />,
    },
    { name: "Registros", path: "/records", icon: <ReceiptIcon /> },
  ];

  if (userRole === UserRole.operator) {
    links.push({
      name: "Casos",
      path: "/cases",
      icon: <Archive />,
    });
  }

  if (userRole === UserRole.admin) {
    links.push({
      name: "Casos",
      path: "/cases",
      icon: <Archive />,
    });
    links.push({
      name: "Usuarios",
      path: "/users",
      icon: <UsersThree />,
    });
  }

  const theme: ThemeType = {
    colors: {
      accent: "#8a49c5",
      primary: "#343a40",
      stripped: "#f8f9fa",
      grayLight: "#f1f3f5",
      grayMedium: "#adb5bd",
      accentDark: "#303563",
      red: "#ff6b6b",
    },
    screens: {
      medium: "600px",
      small: "200px",
    },
  };

  const dispatch = useAppDispatch();

  const logoutHandler = () => {
    dispatch(logout());
  };

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <div className={styles.app}>
        {isLoggedIn && (
          <div className={styles["main-container"]}>
            <Sidebar
              onSignOut={logoutHandler}
              className={styles["sidebar"]}
              links={links}
              icon={<Lock />}
            />
            <main className={styles["content"]}>
              <div className={styles["content__page"]}>
                {userRole === UserRole.admin && (
                  <Routes>
                    <Route path="/home" element={<Home />} />
                    <Route path="/users" element={<Users />} />
                    <Route path="/users/new" element={<NewUser />} />
                    <Route path="/users/:id" element={<EditUser />} />

                    <Route path="/records" element={<Records2 />} />
                    <Route path="/records/new" element={<NewRecord />} />
                    <Route path="/records/:id" element={<EditRecord />} />

                    <Route path="/cases" element={<Cases />} />
                    <Route path="/cases/new" element={<NewCase />} />
                    <Route path="/cases/:id" element={<ViewCase />} />

                    <Route path="/" element={<Navigate to={"/home"} />} />
                  </Routes>
                )}
                {userRole === UserRole.operator && (
                  <Routes>
                    <Route path="/home" element={<Home />} />
                    <Route path="/records" element={<Records2 />} />
                    <Route path="/" element={<Navigate to={"/home"} />} />

                    <Route path="/cases" element={<Cases />} />
                    <Route path="/cases/new" element={<NewCase />} />
                    <Route path="/cases/:id" element={<ViewCase />} />
                  </Routes>
                )}
                {userRole === UserRole.readOnly && (
                  <Routes>
                    <Route path="/home" element={<Home />} />
                    <Route path="/records" element={<Records2 />} />
                    <Route path="/" element={<Navigate to={"/home"} />} />
                  </Routes>
                )}
              </div>
            </main>
          </div>
        )}
        {!isLoggedIn && (
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="*" element={<Navigate to={"/"} replace />} />
          </Routes>
        )}
      </div>
      <ToastContainer theme="colored" hideProgressBar position="top-center" />
    </ThemeProvider>
  );
}

export default App;
