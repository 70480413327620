import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getCase,
  getCaseComments,
  deleteCase,
  closeCase,
} from "../../services/cases.service";
import { Case, CaseStatus, Comment } from "../../interfaces/case.model";
import { toast } from "react-toastify";
import Chat from "./Chat";
import { useAppSelector } from "../../hooks/redux";
import { UserRole } from "../../interfaces/user.model";

const ViewCase = () => {
  const { id } = useParams();
  const [isCaseLoading, setIsCaseLoading] = useState(true);
  const [areCommentLoading, setAreCommentsLoading] = useState(true);

  const [isSending, setIsSending] = useState(false);
  const [comments, setComments] = useState<Comment[]>([]);
  const [title, setTitle] = useState("");
  const navigate = useNavigate();
  const userRole = useAppSelector((state) => state.auth.role);
  const [isCaseOpen, setIsCaseOpen] = useState(false);
  const [hasDocument, setHasDocument] = useState(false);

  const commentAddHandler = (comment: Comment) => {
    setComments((prev) => [...prev, comment]);
  };

  const documentAddHandler = () => {
    setHasDocument(true);
  };

  const caseDeleteHandler = async () => {
    try {
      await deleteCase(id!);
      navigate(`../cases/`, { replace: true });
      toast("Caso eliminado", { type: "success" });
    } catch (err) {}
  };

  const caseCloseHandler = async () => {
    try {
      await closeCase(id!);
      navigate(`../cases/`, { replace: true });
      toast("Caso resuelto", { type: "success" });
    } catch (err) {}
  };

  useEffect(() => {
    const fetchCase = async () => {
      try {
        setIsCaseLoading(true);
        if (id) {
          var response = await getCase(id);

          if (response.data) {
            const caseModel = response.data as Case;

            setIsCaseOpen(caseModel.status === CaseStatus.Opened);
            setTitle(caseModel.creId + " | " + caseModel.folio);
            setHasDocument(caseModel.hasDocument);
            setIsCaseLoading(false);
          }
        }
      } catch (err: any) {
        if (err?.name !== "AxiosError") {
          toast("Error al cargar caso");
        }
        setIsCaseLoading(false);
      }
    };

    const fetchComments = async () => {
      try {
        setAreCommentsLoading(true);
        if (id) {
          var response = await getCaseComments(id);

          if (response.data) {
            const comments = response.data as Comment[];
            console.log(comments);
            setComments(comments);

            setAreCommentsLoading(false);
          }
        }
      } catch (err: any) {
        if (err?.name !== "AxiosError") {
          toast("Error al cargar comentarios");
        }
        setAreCommentsLoading(false);
      }
    };
    fetchCase();
    fetchComments();
  }, []);

  return (
    <Chat
      onDocumentAdd={documentAddHandler}
      hasDocument={hasDocument}
      canSendNewMessages={isCaseOpen}
      canEdit={
        isCaseOpen &&
        (userRole === UserRole.admin || userRole === UserRole.operator)
      }
      canUpload={isCaseOpen && userRole === UserRole.admin}
      canDelete={userRole === UserRole.admin}
      onCommentAdd={commentAddHandler}
      onCaseDelete={caseDeleteHandler}
      onCaseClose={caseCloseHandler}
      caseId={id ?? ""}
      messages={comments}
      title={title}
    />
  );
};

export default ViewCase;
