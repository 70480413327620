import { createSlice } from "@reduxjs/toolkit";
import { Auth } from "../../../interfaces/auth.model";
import jwt from "jwt-decode";
import { UserRole } from "../../../interfaces/user.model";

const token = localStorage.getItem("token");

const initialState: Auth = {
  token: token,
  isLoggedIn: token !== null,
  role: getRole(token),
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    login: (state, action) => {
      console.log(action);
      state.token = action.payload.token;
      state.isLoggedIn = true;
      state.role = action.payload.role;
    },
    logout: (state) => {
      state.token = null;
      state.isLoggedIn = false;
    },
  },
});

export default authSlice;

function getRole(token: string | null): UserRole {
  let role: UserRole = UserRole.readOnly;

  if (token !== null) {
    const user: any = jwt(token);

    if (user["custom:groupId"] === "0") {
      role = UserRole.admin;
    } else if (user["custom:groupId"] === "1") {
      role = UserRole.operator;
    }
  }

  return role;
}
