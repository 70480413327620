import styled from "styled-components";
const ColumnFilter = styled.input`
  outline: none;
  margin-top: 4px;
  background-color: ${({ theme }) => theme.colors.grayLight};
  border: 1px solid ${({ theme }) => theme.colors.grayMedium};
  padding: 8px;
  color: ${({ theme }) => theme.colors.primary};
  border-radius: 4px;
`;

export default ColumnFilter;
