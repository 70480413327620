import { FC, useRef } from "react";
import Button from "../../components/Button/Button";
import Message from "../../components/Message/Message";
import { Comment, CommentCreate } from "../../interfaces/case.model";
import styles from "./Chat.module.css";
import { useState } from "react";
import {
  createComment,
  uploadDocument,
  downloadDocument,
} from "../../services/cases.service";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { Paperclip } from "phosphor-react";
import { toast } from "react-toastify";

interface ChatProps {
  caseId: string;
  title: string;
  messages: Comment[];
  canDelete: boolean;
  canEdit: boolean;
  canSendNewMessages: boolean;
  canUpload: boolean;
  hasDocument: boolean;
  onCaseClose: () => void;
  onCommentAdd: (comment: Comment) => void;
  onCaseDelete: () => void;
  onDocumentAdd: () => void;
}

const Chat: FC<ChatProps> = (props) => {
  const [message, setMessage] = useState("");
  const bottomRef = useRef(null);
  const inputFile = useRef(null);

  const submitHandler = async (event: any) => {
    try {
      event.preventDefault();

      if (!message) return;

      const commentCreate: CommentCreate = {
        blockId: props.caseId,
        content: message,
      };

      const response = await createComment(commentCreate);

      props.onCommentAdd(response.data);

      setMessage("");
    } catch (error) {}
  };

  const deleteHandler = async (event: any) => {
    event.preventDefault();

    Swal.fire({
      title: "¿Seguro que desea eliminar el caso?",
      showCancelButton: true,
      confirmButtonText: "Si, eliminar",
      cancelButtonText: `No, cancelar`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        props.onCaseDelete();
      }
    });
  };

  const closeCaseHandler = async (event: any) => {
    event.preventDefault();

    Swal.fire({
      title: "¿Seguro que desea resolver el caso?",
      showCancelButton: true,
      confirmButtonText: "Si, resolver",
      cancelButtonText: `No, cancelar`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        props.onCaseClose();
      }
    });
  };

  const uploadHandler = async (event: any) => {
    event.preventDefault();
    (inputFile.current! as any).click();
  };

  const viewDocumentHandler = async (event: any) => {
    event.preventDefault();

    const response = await downloadDocument(props.caseId);
    console.log(response);

    // const filename = response.headers["x-file-name"];
    const contentType = response.headers["content-type"];

    console.log(contentType);

    const url = window.URL.createObjectURL(
      new Blob([response.data], { type: contentType })
    );

    window.open(url);

    // const link = document.createElement("a");
    // link.href = url;
    // link.setAttribute("download", filename);

    // // Append to html link element page
    // document.body.appendChild(link);

    // // Start download
    // link.click();

    // // Clean up and remove the link
    // link.parentNode!.removeChild(link);
  };

  const handleFileChange = async (event: any) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }

    console.log("fileObj is", fileObj);

    // 👇️ reset file input
    event.target.value = null;

    // // 👇️ is now empty
    // console.log(event.target.files);

    // 👇️ can still access file object here
    // console.log(fileObj);
    // console.log(fileObj.name);

    const extension = fileObj.name.split(".").pop();

    if (
      extension !== "pdf" &&
      extension !== "png" &&
      extension !== "jpg" &&
      extension !== "jpeg"
    ) {
      toast("Tipo de archivo no permitido", { type: "error" });
      return;
    } else if (fileObj.size > 2097152) {
      toast("El archivo no debe superar los 2 MB", { type: "error" });
      return;
    }

    toast("Subiendo archivo...");

    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append("myFile", fileObj, fileObj.name);

    // Details of the uploaded file
    console.log(fileObj);

    await uploadDocument(props.caseId, formData);

    props.onDocumentAdd();

    toast("Carga completa", { type: "success" });
  };

  useEffect(() => {
    (bottomRef.current as any).scrollIntoView({ behavior: "smooth" });
  }, [props.messages]);

  return (
    <div className={styles.chat}>
      <div className={styles["chat__header"]}>
        <span>{props.title}</span>
        <div className={styles["chat__header__actions"]}>
          {props.hasDocument && (
            <Button onClick={viewDocumentHandler}>Ver documento</Button>
          )}
          {props.canEdit && (
            <Button onClick={closeCaseHandler}>Resolver</Button>
          )}
          {props.canDelete && (
            <Button danger onClick={deleteHandler}>
              Eliminar
            </Button>
          )}
        </div>
      </div>
      <div className={styles["chat__messages"]}>
        {props.messages.map((message) => {
          return (
            <Message
              content={message.content}
              date={message.timestamp}
              user={message.username}
              key={message.id}
              id={message.id}
            />
          );
        })}
        <div ref={bottomRef} />
      </div>

      <form className={styles["chat__input"]} onSubmit={submitHandler}>
        <input
          disabled={!props.canSendNewMessages}
          type="text"
          value={message}
          onChange={(event: any) => setMessage(event.target.value)}
        />
        {props.canUpload && (
          <>
            <input
              type="file"
              id="file"
              ref={inputFile}
              style={{ display: "none" }}
              onChange={handleFileChange}
              accept=".pdf,.png,.jpg,.jpeg"
            />
            <button
              className={styles["chat__input__upload"]}
              onClick={uploadHandler}
            >
              <Paperclip />
            </button>
          </>
        )}

        <Button
          primary
          type="submit"
          disabled={!props.canSendNewMessages || !message}
        >
          Enviar
        </Button>
      </form>
    </div>
  );
};

export default Chat;
