import { useFormik } from "formik";
import Button from "../../components/Button/Button";
import FormInput from "../../components/FormInput/FormInput";
import { Form, FormActions } from "../Form/Form";
import validationSchema from "./validationSchema";
import { editUser, getUserById } from "../../services/users.service";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserEdit, User } from "../../interfaces/user.model";
import { toast } from "react-toastify";
import Spinner from "../../components/Spinner/Spinner";

interface FormValues {
  username: string;
  isActive: boolean;
}

const EditUser = () => {
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();

  const navigate = useNavigate();

  const initialValues: FormValues = {
    username: "",
    isActive: false,
  };

  const submitHandler = async (values: FormValues) => {
    try {
      setIsSaving(true);
      const user: UserEdit = {
        isActive: values.isActive,
      };

      await editUser(id as string, user);

      navigate("../users", { replace: true });
      toast("Cambios guardados", { type: "success" });

      setIsSaving(false);
    } catch (err: any) {
      if (err?.name !== "AxiosError") {
        toast("Error desconocido al guardar usuario");
      }
      setIsSaving(false);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: submitHandler,
    validateOnMount: true,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        if (id) {
          var response = await getUserById(id);

          if (response.data) {
            const user = response.data as User;

            formik.setFieldValue("username", user.username);
            formik.setFieldValue("isActive", user.isActive);

            setIsLoading(false);
          }
        }
      } catch (err: any) {
        if (err?.name !== "AxiosError") {
          toast("Error al cargar usuario");
        }
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  if (isLoading) {
    return (
      <div className="page-content">
        <Spinner />
      </div>
    );
  } else {
    return (
      <Form onSubmit={formik.handleSubmit}>
        <FormInput
          label="Nombre de usuario"
          disabled
          textTransform="lowercase"
          {...formik.getFieldProps("username")}
          error={formik.touched.username ? formik.errors.username : ""}
        />
        <FormInput
          label="Activo"
          type="checkbox"
          checked={formik.values.isActive}
          {...formik.getFieldProps("isActive")}
        />
        <FormActions>
          <Button primary type="submit" disabled={isSaving}>
            Guardar
          </Button>
        </FormActions>
      </Form>
    );
  }
};

export default EditUser;
