import styled from "styled-components";
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 18px;
  max-width: 400px;
  gap: 8px;
  font-family: "Poppins", sans-serif;
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  & > label {
    font-family: "Poppins", sans-serif;
  }
`;

export const FormActions = styled.div`
  margin-top: 18px;
  display: flex;
  justify-content: stretch;
  flex-direction: column;
`;
