import {
  compareItems,
  RankingInfo,
  rankings,
  rankItem,
} from "@tanstack/match-sorter-utils";
import {
  Column,
  createTable,
  TableInstance,
  useTableInstance,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  sortingFns,
  getSortedRowModel,
} from "@tanstack/react-table";
import React, { useEffect, useState } from "react";
import "../../styles/_table.css";
import "../../styles/_page.css";
import { Circle } from "phosphor-react";
import { CSVLink } from "react-csv";
import Spinner from "../../components/Spinner/Spinner";
import ColumnFilter from "../../components/ColumnFilter/ColumnFilter";
import Button from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import { getUsers } from "../../services/users.service";
import { User, UserRole } from "../../interfaces/user.model";
import {
  PaginationButton,
  PaginationButtonsContainer,
  PaginationContainer,
  PaginationInput,
} from "../../components/CustomPagination/CustomPagination";

let table = createTable()
  .setRowType<User>()
  .setFilterMetaType<RankingInfo>()
  .setOptions({
    filterFns: {
      fuzzy: (row, columnId, value, addMeta) => {
        // Rank the item
        const itemRank = rankItem(row.getValue(columnId), value, {
          threshold: rankings.MATCHES,
        });

        // Store the ranking info
        addMeta(itemRank);

        // Return if the item should be filtered in/out
        return itemRank.passed;
      },
    },
    sortingFns: {
      fuzzy: (rowA, rowB, columnId) => {
        let dir = 0;

        // Only sort by rank if the column has ranking information
        if (rowA.columnFiltersMeta[columnId]) {
          dir = compareItems(
            rowA.columnFiltersMeta[columnId]!,
            rowB.columnFiltersMeta[columnId]!
          );
        }

        // Provide a fallback for when the item ranks are equal
        return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir;
      },
    },
  });

const Users = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const navigate = useNavigate();
  // const [globalFilter, setGlobalFilter] = React.useState("");

  const columns = React.useMemo(
    () => [
      table.createDataColumn("username", {
        header: "Nombre de usuario",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      }),
      table.createDataColumn("role", {
        header: "Tipo",
        cell: (info) =>
          info.getValue() === UserRole.operator ? "operador" : "solo lectura",
        footer: (props) => props.column.id,
      }),
      table.createDataColumn("dateAdded", {
        header: "Fecha de creacion",
        cell: (info) => getFormattedDate(info.getValue()),
        footer: (props) => props.column.id,
      }),
      table.createDataColumn("isActive", {
        header: "Activo",
        enableColumnFilter: false,
        cell: (info) =>
          info.getValue() === true ? (
            <Circle weight="fill" color="#69db7c" />
          ) : (
            <Circle weight="fill" color="#ff6b6b" />
          ),
        footer: (props) => props.column.id,
      }),
      // table.createDataColumn("protected", {
      //   header: "Indicador",
      //   cell: (info) =>
      //     info.getValue() === true ? (
      //       <Circle weight="fill" color="#69db7c" />
      //     ) : (
      //       <Circle weight="fill" color="#ff6b6b" />
      //     ),
      //   footer: (props) => props.column.id,
      //   enableColumnFilter: false,
      // }),
    ],
    []
  );

  const [data, setData] = React.useState([]);
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const response = await getUsers();

      if (response) {
        setData(response.data);
      }
      setIsLoading(false);
    };

    fetchData();
  }, []);

  const instance = useTableInstance(table, {
    data,
    columns,
    state: {
      columnFilters,
      // globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    // globalFilterFn: "fuzzy",
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
  });

  useEffect(() => {
    instance.setPageSize(200);
  }, []);

  const csvHeaders = [
    { label: "Nombre de usuario", key: "username" },
    { label: "Fecha de creacion", key: "addedDate" },
    { label: "Activo", key: "isActive" },
  ];

  return (
    <div className="page-container">
      <div className="page-header">
        <h1>Usuarios</h1>
        <div className="page-header__actions">
          <CSVLink
            className="export-link"
            filename="Usuarios.csv"
            data={
              instance
                .getFilteredRowModel()
                .rows.map((row) => row.original) as any
            }
            headers={csvHeaders}
          >
            Exportar
          </CSVLink>

          <Button primary onClick={() => navigate("/users/new")}>
            Agregar
          </Button>
        </div>
      </div>

      <div className="page-content">
        {isLoading && <Spinner />}
        {!isLoading && (
          <>
            <div className="filter">
              <div className="filter__actions">
                {/* <button
                  onClick={() =>
                    instance.getColumn("protected").setFilterValue(null)
                  }
                >
                  Ver todos
                </button>
                <button
                  onClick={() =>
                    instance.getColumn("protected").setFilterValue(true)
                  }
                >
                  Ver verdes
                </button>
                <button
                  onClick={() =>
                    instance.getColumn("protected").setFilterValue(false)
                  }
                >
                  Ver rojos
                </button> */}
              </div>
              <div className="filter__search">
                {/* <DebouncedInput
                value={globalFilter ?? ""}
                onChange={(value) => setGlobalFilter(String(value))}
                className="p-2 font-lg shadow border border-block"
                placeholder="Search all columns..."
              /> */}
                {/* <Search onSearchChanged={searchTextChangeHandler} /> */}
              </div>
            </div>

            <table className="table table-stripped">
              <thead>
                {instance.getHeaderGroups().map((headerGroup) => (
                  <tr className="table__header" key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <th key={header.id} colSpan={header.colSpan}>
                          {header.isPlaceholder ? null : (
                            <>
                              <div
                                {...{
                                  className: header.column.getCanSort()
                                    ? "cursor-pointer select-none"
                                    : "",
                                  onClick:
                                    header.column.getToggleSortingHandler(),
                                }}
                              >
                                {header.renderHeader()}
                                {{
                                  asc: " 🔼",
                                  desc: " 🔽",
                                }[header.column.getIsSorted() as string] ??
                                  null}
                              </div>
                              {header.column.getCanFilter() ? (
                                <div>
                                  <Filter
                                    column={header.column}
                                    instance={instance}
                                  />
                                </div>
                              ) : null}
                            </>
                          )}
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody>
                {instance.getRowModel().rows.map((row) => {
                  return (
                    <tr
                      key={row.id}
                      onClick={() =>
                        row.original?.id &&
                        navigate("/users/" + row.original?.id)
                      }
                    >
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <td
                            key={cell.id}
                            data-label={cell.column.columnDef.header}
                          >
                            {cell.renderCell()}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <PaginationContainer>
              <div>
                {instance.getPrePaginationRowModel().rows.length} registros
              </div>
              <PaginationButtonsContainer>
                <PaginationButton
                  className="pagination-btn"
                  onClick={() => instance.setPageIndex(0)}
                  disabled={!instance.getCanPreviousPage()}
                >
                  {"<<"}
                </PaginationButton>
                <PaginationButton
                  className="border rounded p-1"
                  onClick={() => instance.previousPage()}
                  disabled={!instance.getCanPreviousPage()}
                >
                  {"<"}
                </PaginationButton>

                <PaginationButton
                  className="border rounded p-1"
                  onClick={() => instance.nextPage()}
                  disabled={!instance.getCanNextPage()}
                >
                  {">"}
                </PaginationButton>
                <PaginationButton
                  className="border rounded p-1"
                  onClick={() =>
                    instance.setPageIndex(instance.getPageCount() - 1)
                  }
                  disabled={!instance.getCanNextPage()}
                >
                  {">>"}
                </PaginationButton>
              </PaginationButtonsContainer>
              <div>
                <span className="flex items-center gap-1">
                  Pagina
                  <strong>
                    {instance.getState().pagination.pageIndex + 1} de{" "}
                    {instance.getPageCount()}
                  </strong>
                </span>
                <span className="flex items-center gap-1">
                  | Ir a pagina:&nbsp;&nbsp;
                  <PaginationInput
                    type="number"
                    defaultValue={instance.getState().pagination.pageIndex + 1}
                    onChange={(e) => {
                      const page = e.target.value
                        ? Number(e.target.value) - 1
                        : 0;
                      instance.setPageIndex(page);
                    }}
                    className="border p-1 rounded w-16"
                  />
                </span>
              </div>
              {/* <select
              value={instance.getState().pagination.pageSize}
              onChange={(e) => {
                instance.setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select> */}
            </PaginationContainer>

            {/* <Pagination
            currentPage={instance.getState().pagination.pageIndex + 1}
            itemsPerPage={200}
            maxPages={5}
            totalItems={instance.getPrePaginationRowModel().rows.length}
            totalPages={instance.getPageCount()}
            onPageChange={(page: number) => instance.setPageIndex(page - 1)}
          /> */}
          </>
        )}
      </div>
    </div>
  );
};

export default Users;

function Filter({
  column,
  instance,
}: {
  column: Column<any>;
  instance: TableInstance<any>;
}) {
  const firstValue = instance
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id);

  const columnFilterValue = column.getFilterValue();

  const sortedUniqueValues = React.useMemo(
    () =>
      typeof firstValue === "number"
        ? []
        : Array.from(column.getFacetedUniqueValues().keys()).sort(),
    [column.getFacetedUniqueValues()]
  );

  return (
    // typeof firstValue === "number" ? (
    //   <div>
    //     <div className="flex space-x-2">
    //       <DebouncedInput
    //         type="number"
    //         min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")}
    //         max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")}
    //         value={(columnFilterValue as [number, number])?.[0] ?? ""}
    //         onChange={(value) =>
    //           column.setFilterValue((old: [number, number]) => [value, old?.[1]])
    //         }
    //         placeholder={`Min ${
    //           column.getFacetedMinMaxValues()?.[0]
    //             ? `(${column.getFacetedMinMaxValues()?.[0]})`
    //             : ""
    //         }`}
    //         className="w-24 border shadow rounded"
    //       />
    //       <DebouncedInput
    //         type="number"
    //         min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")}
    //         max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")}
    //         value={(columnFilterValue as [number, number])?.[1] ?? ""}
    //         onChange={(value) =>
    //           column.setFilterValue((old: [number, number]) => [old?.[0], value])
    //         }
    //         placeholder={`Max ${
    //           column.getFacetedMinMaxValues()?.[1]
    //             ? `(${column.getFacetedMinMaxValues()?.[1]})`
    //             : ""
    //         }`}
    //         className="w-24 border shadow rounded"
    //       />
    //     </div>
    //     <div className="h-1" />
    //   </div>
    // ) : (
    <>
      <DebouncedInput
        type="text"
        value={(columnFilterValue ?? "") as string}
        onChange={(value) => column.setFilterValue(value)}
        placeholder="Buscar"
        list={column.id + "list"}
      />
      <div className="h-1" />
    </>
  );
  // );
}

// A debounced input react component
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange">) {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <ColumnFilter
      {...props}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  );
}

function getFormattedDate(date: Date): string {
  const D = new Date(date);

  return D.toLocaleString();
}
