import styled from "styled-components";

const StyledSpinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  flex: 1;
  & img {
    width: 48px;
  }
`;

export default StyledSpinner;
