import styled from "styled-components";
const StyledLogin = styled.div`
  background-color: "#f2f5f7";
  & > div {
    margin-top: 32px;
  }
`;

export const LoginTitle = styled.h1`
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 32px;
`;

export default StyledLogin;
