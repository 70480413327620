import axios from "./axios-api";
import { UserCreate, UserEdit } from "../interfaces/user.model";

export const getUsers = () => {
  return axios.get<any>(`/users`);
};

export const getUserById = (id: string) => {
  return axios.get<any>(`/users/${id}`);
};

export const createUser = (user: UserCreate) => {
  return axios.post<any>(`/users`, user);
};

export const editUser = (userId: string, user: UserEdit) => {
  return axios.put<any>(`/users/${userId}`, user);
};
