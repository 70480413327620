import styled from "styled-components";

interface CardProps {
  padding?: number;
}

const Card = styled.div<CardProps>`
  background-color: #fff;
  border: none;
  border-radius: 18px;
  padding: ${({ padding }) => (padding ? padding + "px" : "0")};
  box-shadow: 0 5px 9px 0 rgb(37, 103, 141 / 0.04);
`;

export default Card;
