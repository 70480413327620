import { useFormik } from "formik";
import Button from "../../components/Button/Button";
import FormInput from "../../components/FormInput/FormInput";
import { Form, FormActions } from "../Form/Form";
import validationSchema from "./validationSchema";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { CaseCreate } from "../../interfaces/case.model";
import { createCase } from "../../services/cases.service";

interface FormValues {
  creId?: string;
  folio: string;
  openedDate?: string;
}

const NewCase = () => {
  const [isSaving, setIsSaving] = useState(false);
  const navigate = useNavigate();

  const initialValues: FormValues = {
    creId: "",
    folio: "",
    openedDate: "",
  };

  const submitHandler = async (values: FormValues) => {
    try {
      setIsSaving(true);
      const model: CaseCreate = {
        creId: values.creId!,
        openedDate: new Date(values.openedDate!),
        folio: values.folio!,
      };

      const response = await createCase(model);

      if (response.data) {
        navigate(`../cases/${response.data.id}`, { replace: true });
        toast("Caso agregado", { type: "success" });
      }

      setIsSaving(false);
    } catch (err: any) {
      if (err?.name !== "AxiosError") {
        toast("Error desconocido al guardar caso");
      }
      setIsSaving(false);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: submitHandler,
    validateOnMount: true,
    validationSchema: validationSchema,
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <FormInput
        label="Permiso*"
        maxLength={24}
        {...formik.getFieldProps("creId")}
        error={formik.touched.creId ? formik.errors.creId : ""}
      />
      <FormInput
        label="Folio*"
        maxLength={50}
        {...formik.getFieldProps("folio")}
        error={formik.touched.folio ? formik.errors.folio : ""}
      />
      <FormInput
        label="Fecha*"
        type="date"
        {...formik.getFieldProps("openedDate")}
        error={formik.touched.openedDate ? formik.errors.openedDate : ""}
      />
      <FormActions>
        <Button primary type="submit" disabled={!formik.isValid || isSaving}>
          Guardar
        </Button>
      </FormActions>
    </Form>
  );
};

export default NewCase;
