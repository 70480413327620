import "./Home.css";
import {
  PageContainer,
  PageContent,
} from "../../components/Page/PageContainer.styled";
import Button from "../../components/Button/Button";
import { ChangeEvent, useRef } from "react";
import { Form } from "../Form/Form";
import { useState } from "react";
import { searchRecords } from "../../services/records.service";
import { RecordSearch } from "../../interfaces/record.model";
import SearchResult from "../SearchResult/SearchResult";
import Spinner from "../../components/Spinner/Spinner";
import Flex from "../../components/Flex/Flex";

const Home = () => {
  const [searchText, setSearchText] = useState("");
  const [records, setRecords] = useState([]);
  const [searchResultVisible, setSearchResultVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const searchByCreIdRef = useRef(null);
  const searchByNumberRef = useRef(null);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      setIsLoading(true);
      const recordSearch: RecordSearch = {
        type: (searchByCreIdRef.current as any).checked ? 0 : 1,
        text: searchText,
      };

      const response = await searchRecords(recordSearch);

      console.log(response.data);

      setRecords(response.data);

      setSearchResultVisible(true);
      setIsLoading(false);
      // if (response.data) {
      //    navigate("/search-result", { records: response.data });
      // }
    } catch (err) {
      setIsLoading(false);
    }
  };

  const handleOnChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setSearchText(event.target.value);
  };

  return (
    <PageContainer>
      <PageContent>
        <Form onSubmit={handleSubmit}>
          <textarea rows={15} onChange={handleOnChange} />
          <Flex>
            <label>
              <input
                ref={searchByCreIdRef}
                id="creId"
                name="search-type"
                type="radio"
                defaultChecked
                value={0}
              />
              Permiso
            </label>
            <label>
              <input
                ref={searchByNumberRef}
                id="estacion"
                name="search-type"
                type="radio"
                value={1}
              />
              Estacion
            </label>
          </Flex>
          <Button primary type="submit" disabled={searchText.length === 0}>
            Buscar
          </Button>
        </Form>
        {isLoading && <Spinner />}
        {!isLoading && searchResultVisible && (
          <SearchResult records={records} />
        )}
      </PageContent>
    </PageContainer>
  );
};

export default Home;
