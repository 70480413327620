import { FC } from "react";
import image from "../../assets/img/spinner.svg";
import StyledSpinner from "./styles/Spinner.styled";

interface SpinnerProps {
  className?: string;
}

const Spinner: FC<SpinnerProps> = ({ className }) => {
  return (
    <StyledSpinner>
      <img src={image} alt="loading spinner" />
    </StyledSpinner>
    // <div className={`${classes.spinner} ${className ? className : ""}`}>
    // </div>
  );
};

export default Spinner;
