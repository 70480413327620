import { FC } from "react";
import styles from "./Message.module.css";

export interface MessageProps {
  id: string;
  content: string;
  user: string;
  date: Date;
}

const Message: FC<MessageProps> = ({ content, user, date }) => {
  return (
    <div className={styles.message}>
      <div className={styles.message__user}>{user}</div>
      <div className={styles.message__content}>{content}</div>
      <div className={styles.message__date}>{date.toString()}</div>
    </div>
  );
};

export default Message;
