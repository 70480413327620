import { useFormik } from "formik";
import Button from "../../components/Button/Button";
import FormInput from "../../components/FormInput/FormInput";
import { Form, FormActions } from "../Form/Form";
import validationSchema from "./validationSchema";
import { getRecordById, updateRecord } from "../../services/records.service";
import { RecordCreate } from "../../interfaces/record.model";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Record from "../../interfaces/record.model";
import Spinner from "../../components/Spinner/Spinner";
import { toast } from "react-toastify";

interface FormValues {
  creId: string;
  name: string;
  number?: string;
  streetAddress: string;
  postalCode: string;
  municipality: string;
  state: string;
  protected: boolean;
  settlement: string;
}

const EditRecord = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const { id } = useParams();

  const navigate = useNavigate();

  const initialValues: FormValues = {
    creId: "",
    municipality: "",
    name: "",
    number: "",
    postalCode: "",
    protected: false,
    state: "",
    streetAddress: "",
    settlement: "",
  };

  const submitHandler = async (values: FormValues) => {
    try {
      setIsSaving(true);
      const record: RecordCreate = {
        creId: values.creId,
        municipality: values.municipality,
        name: values.name,
        number: values.number,
        postalCode: values.postalCode,
        protected: values.protected,
        state: values.state,
        settlement: values.settlement,
        streetAddress: values.streetAddress,
      };

      const response = await updateRecord(id as string, record);

      if (response.data) {
        console.log(response.data);
        navigate("../records", { replace: true });
        toast("Registro guardado", { type: "success" });
      }

      setIsSaving(false);
    } catch (err: any) {
      if (err?.name !== "AxiosError") {
        toast("Error desconocido al guardar registro");
      }
      setIsSaving(false);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: submitHandler,
    validateOnMount: true,
    validationSchema: validationSchema,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        if (id) {
          var response = await getRecordById(id);

          if (response.data) {
            const record = response.data as Record;

            formik.setFieldValue("creId", record.creId);
            formik.setFieldValue("name", record.name);
            formik.setFieldValue("number", record.number);
            formik.setFieldValue("streetAddress", record.streetAddress);
            formik.setFieldValue("settlement", record.settlement);
            formik.setFieldValue("postalCode", record.postalCode);
            formik.setFieldValue("municipality", record.municipality);
            formik.setFieldValue("state", record.state);
            formik.setFieldValue("protected", record.protected);
            setIsLoading(false);
          }
        }
      } catch (err) {
        toast("Error al cargar registro", { type: "error" });
      }
    };

    fetchData();
  }, []);

  if (isLoading) {
    return (
      <div className="page-content">
        <Spinner />
      </div>
    );
  } else {
    return (
      <Form onSubmit={formik.handleSubmit}>
        <FormInput
          label="Permiso"
          {...formik.getFieldProps("creId")}
          error={formik.touched.creId ? formik.errors.creId : ""}
        />
        <FormInput
          label="Razon social"
          {...formik.getFieldProps("name")}
          error={formik.touched.name ? formik.errors.name : ""}
        />
        <FormInput
          label="Estacion"
          {...formik.getFieldProps("number")}
          error={formik.touched.number ? formik.errors.number : ""}
        />
        <FormInput
          label="Domicilio"
          {...formik.getFieldProps("streetAddress")}
          error={
            formik.touched.streetAddress ? formik.errors.streetAddress : ""
          }
        />
        <FormInput
          label="Colonia"
          {...formik.getFieldProps("settlement")}
          error={formik.touched.settlement ? formik.errors.settlement : ""}
        />
        <FormInput
          label="CP"
          maxLength={5}
          {...formik.getFieldProps("postalCode")}
          error={formik.touched.postalCode ? formik.errors.postalCode : ""}
        />
        <FormInput
          label="Municipio"
          {...formik.getFieldProps("municipality")}
          error={formik.touched.municipality ? formik.errors.municipality : ""}
        />
        <FormInput
          label="Estado"
          {...formik.getFieldProps("state")}
          error={formik.touched.state ? formik.errors.state : ""}
        />
        <FormInput
          label="Indicador"
          type="checkbox"
          checked={formik.values.protected}
          {...formik.getFieldProps("protected")}
          error={formik.touched.protected ? formik.errors.protected : ""}
        />
        <FormActions>
          <Button primary type="submit" disabled={!formik.isValid || isSaving}>
            Guardar
          </Button>
        </FormActions>
      </Form>
    );
  }
};

export default EditRecord;
