import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { login as loginService } from "../../../services/account.service";
import authSlice from "./auth-slice";
import jwt from "jwt-decode";
import { UserRole } from "../../../interfaces/user.model";

export interface AuthSuccessResult {
  token: string;
  role: UserRole;
}

export const login =
  (
    username: string,
    password: string
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    const response = await loginService(username, password);

    const token = response.data.token;
    const refreshToken = response.data.refreshToken;

    if (token) {
      localStorage.setItem("token", token);
      localStorage.setItem("refresh_token", refreshToken);
      const user: any = jwt(token);

      let role = UserRole.readOnly;

      if (user["custom:groupId"] === "0") {
        role = UserRole.admin;
      } else if (user["custom:groupId"] === "1") {
        role = UserRole.operator;
      }

      const successResult: AuthSuccessResult = {
        token: token,
        role: role,
      };

      dispatch(authSlice.actions.login(successResult));
    }
  };

export const logout =
  (): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
    localStorage.removeItem("token");
    dispatch(authSlice.actions.logout());
  };
