import { NavLink } from "react-router-dom";
import styled from "styled-components";

interface StyledSidebarProps {
  isExpanded: boolean;
}
export const StyledSidebar = styled.aside<StyledSidebarProps>`
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 150px;
  z-index: 1;
  border-right: 1px solid ${({ theme }) => theme.colors.grayLight};
  background-color: #f7f7f7;

  @media screen and (max-width: ${({ theme }) => theme.screens.medium}) {
    grid-row: 1/-1;
    grid-column: 1/ -1;

    ${({ isExpanded }) =>
      !isExpanded &&
      `
        margin: 0;
        width: 0;
        padding: 0;
        `}
  }
`;

export const SidebarContent = styled.div<StyledSidebarProps>`
  flex: 1;
  /* grid-row: 1/-1;
  grid-column: 1/ -1;
  flex: 1;
  @media screen and (max-width: ${({ theme }) => theme.screens.medium}) {
    display: ${({ isExpanded }) => (isExpanded ? "block" : "none")};
  } */
`;

// export const Logo = styled.div`
//   height: calc(100px - 30px);
// `;

export const MenuContainer = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

interface StyleNavLinkProps {
  isActive?: boolean;
}

export const StyledNavLink = styled(NavLink)<StyleNavLinkProps>`
  text-decoration: none;
  border-radius: 8px;
  padding: 12px 12px;
  color: var(--primary);
  font-weight: 500;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 14px;
  color: #7b838c;

  &.active {
    color: ${({ theme }) => theme.colors.accentDark};
    background-color: #fff;
    font-weight: 700;

    svg {
      color: ${({ theme }) => theme.colors.accent};
    }
  }

  & > svg {
    font-size: 24px;
  }

  &:hover {
    font-weight: 700;
  }
`;

export const Footer = styled.footer`
  & > button {
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: center;
    width: 100%;
    padding: 12px;
    font-size: 16px;

    flex: 1;
    align-self: flex-end;
  }
`;

interface ToggleButtonProps {
  isSidebarExpanded: boolean;
}

export const ToggleButton = styled.button<ToggleButtonProps>`
  position: absolute;
  margin-left: 30px;
  top: 4px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.primary};
  transform: translateX(-50%);
  line-height: 50px;
  margin-left: 0;

  border-radius: 50%;
  padding: 8px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  background-color: #fff;
  z-index: 99;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 10%);
  border: none;

  border: 1px solid ${({ theme }) => theme.colors.primary};

  @media screen and (max-width: ${({ theme }) => theme.screens.medium}) {
    left: ${({ isSidebarExpanded }) =>
      isSidebarExpanded ? "calc(100% - 28px)" : "28px"};
  }

  @media screen and (min-width: ${({ theme }) => theme.screens.medium}) {
    display: none;
  }
`;

export const SidebarContainer = styled.div`
  position: relative;

  @media screen and (max-width: ${({ theme }) => theme.screens.medium}) {
    /* grid-row: 1/-1;
    grid-column: 1/ -1; */
    display: none;
  }
`;
