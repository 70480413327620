import { FC } from "react";
import { FormGroup } from "../../pages/Form/Form";
import styled from "styled-components";

interface FormSelectProps {
  name: string;
  label: string;
  error?: string;
  maxLength?: number;
  textTransform?: "uppercase" | "lowercase";
  type?: string;
  disabled?: boolean;
  options: any;
}

const FormSelect: FC<FormSelectProps> = ({
  name,
  label,
  error,
  type,
  textTransform,
  maxLength,
  disabled,
  options,
  ...rest
}) => {
  return (
    <FormGroup>
      <label htmlFor={name}>{label}</label>
      <StyledSelect
        disabled={disabled}
        id={name}
        error={error ? true : false}
        {...rest}
      >
        {options.map((opt: any, index: number) => {
          return (
            <option key={index} value={opt.value}>
              {opt.description}
            </option>
          );
        })}
      </StyledSelect>
      {error && <ErrorSpan>{error}</ErrorSpan>}
    </FormGroup>
  );
};

interface StyledSelectProps {
  textTransform?: "uppercase" | "lowercase";
  error?: boolean;
}

const StyledSelect = styled.select<StyledSelectProps>`
  text-transform: ${({ textTransform }) => textTransform};
  font-family: "Poppins", sans-serif;

  border-radius: 4px;
  outline: none;
  font-size: 16px;
  border: ${({ theme, error }) =>
    error
      ? "1px solid " + theme.colors.red
      : "1px solid " + theme.colors.primary};
  padding: 6px;

  &:focus {
    border: ${({ theme }) => "1px solid " + theme.colors.accent};
    box-shadow: ${({ theme }) => "0px .5px 2px " + theme.colors.accent};
  }
`;

const ErrorSpan = styled.span`
  font-size: 10px;
  font-family: "Poppins", sans-serif;
  color: ${({ theme }) => theme.colors.red};
`;

export default FormSelect;
