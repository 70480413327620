export enum CaseStatus {
  Opened,
  Closed,
}

export interface Case {
  id: string;
  openedDate: Date;
  closedDate?: Date;
  status: CaseStatus;
  folio: string;
  creId: string;
  user: string;
  createdDate: Date;
  hasDocument: boolean;
}

export interface CaseCreate {
  openedDate: Date;
  folio: string;
  creId: string;
}

export interface Comment {
  content: string;
  timestamp: Date;
  username: string;
  id: string;
}

export interface CommentCreate {
  content: string;
  blockId: string;
}
