import { useFormik } from "formik";
import Button from "../../components/Button/Button";
import FormInput from "../../components/FormInput/FormInput";
import { Form, FormActions } from "../Form/Form";
import validationSchema from "./validationSchema";
import { createRecord } from "../../services/records.service";
import { RecordCreate } from "../../interfaces/record.model";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

interface FormValues {
  creId: string;
  name: string;
  number?: string;
  streetAddress: string;
  postalCode: string;
  municipality: string;
  state: string;
  protected: boolean;
  settlement: string;
}

const NewRecord = () => {
  const [isSaving, setIsSaving] = useState(false);
  const navigate = useNavigate();

  const initialValues: FormValues = {
    creId: "",
    municipality: "",
    name: "",
    number: "",
    postalCode: "",
    protected: false,
    state: "",
    streetAddress: "",
    settlement: "",
  };

  const submitHandler = async (values: FormValues) => {
    try {
      setIsSaving(true);
      const record: RecordCreate = {
        creId: values.creId,
        municipality: values.municipality,
        name: values.name,
        number: values.number,
        postalCode: values.postalCode,
        protected: values.protected,
        state: values.state,
        settlement: values.settlement,
        streetAddress: values.streetAddress,
      };

      const response = await createRecord(record);

      if (response.data) {
        navigate("../records", { replace: true });
        toast("Registro agregado", { type: "success" });
      }

      setIsSaving(false);
    } catch (err: any) {
      if (err?.name !== "AxiosError") {
        toast("Error desconocido al guardar registro");
      }
      setIsSaving(false);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: submitHandler,
    validateOnMount: true,
    validationSchema: validationSchema,
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <FormInput
        label="Permiso*"
        {...formik.getFieldProps("creId")}
        error={formik.touched.creId ? formik.errors.creId : ""}
      />
      <FormInput
        label="Razon social*"
        {...formik.getFieldProps("name")}
        error={formik.touched.name ? formik.errors.name : ""}
      />
      <FormInput
        label="Estacion"
        {...formik.getFieldProps("number")}
        error={formik.touched.number ? formik.errors.number : ""}
      />
      <FormInput
        label="Domicilio*"
        {...formik.getFieldProps("streetAddress")}
        error={formik.touched.streetAddress ? formik.errors.streetAddress : ""}
      />
      <FormInput
        label="Colonia*"
        {...formik.getFieldProps("settlement")}
        error={formik.touched.settlement ? formik.errors.settlement : ""}
      />
      <FormInput
        label="CP*"
        maxLength={5}
        {...formik.getFieldProps("postalCode")}
        error={formik.touched.postalCode ? formik.errors.postalCode : ""}
      />
      <FormInput
        label="Municipio*"
        {...formik.getFieldProps("municipality")}
        error={formik.touched.municipality ? formik.errors.municipality : ""}
      />
      <FormInput
        label="Estado*"
        {...formik.getFieldProps("state")}
        error={formik.touched.state ? formik.errors.state : ""}
      />
      <FormInput
        label="Indicador"
        type="checkbox"
        {...formik.getFieldProps("protected")}
        error={formik.touched.protected ? formik.errors.protected : ""}
      />
      <FormActions>
        <Button primary type="submit" disabled={!formik.isValid || isSaving}>
          Guardar
        </Button>
      </FormActions>
    </Form>
  );
};

export default NewRecord;
