import axios from "./axios-api";
import { CaseCreate, CommentCreate } from "../interfaces/case.model";

export const getCases = () => {
  return axios.get<any>(`/blocks`);
};

export const deleteCase = (id: string) => {
  return axios.delete<any>(`/blocks/${id}`);
};

export const closeCase = (id: string) => {
  return axios.post<any>(`/blocks/${id}/close`);
};

export const createCase = (caseCreate: CaseCreate) => {
  return axios.post<any>("/blocks", caseCreate);
};

export const getCase = (id: string) => {
  return axios.get<any>(`/blocks/${id}`);
};

export const getCaseComments = (id: string) => {
  return axios.get<any>(`/comments?blockId=${id}`);
};

export const createComment = (comment: CommentCreate) => {
  return axios.post<any>(`/comments`, comment);
};

export const uploadDocument = (id: string, data: FormData) => {
  return axios.post<any>(`/blocks/${id}/upload`, data);
};

export const downloadDocument = (id: string) => {
  return axios.get<any>(`/blocks/${id}/download`, {
    responseType: "blob",
  });
};
