import { useFormik } from "formik";
import Button from "../../components/Button/Button";
import FormInput from "../../components/FormInput/FormInput";
import { Form, FormActions } from "../Form/Form";
import validationSchema from "./validationSchema";
import { createUser } from "../../services/users.service";
import { UserCreate, UserRole } from "../../interfaces/user.model";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import FormSelect from "../../components/FormSelect/FormSelect";

interface FormValues {
  username: string;
  role: UserRole;
  password: string;
  passwordConfirmation: string;
}

const NewUser = () => {
  const [isSaving, setIsSaving] = useState(false);
  const navigate = useNavigate();

  const initialValues: FormValues = {
    username: "",
    role: UserRole.operator,
    password: "",
    passwordConfirmation: "",
  };

  const submitHandler = async (values: FormValues) => {
    try {
      setIsSaving(true);
      const user: UserCreate = {
        username: values.username,
        password: values.password,
        role: values.role,
      };

      console.log(user);

      const response = await createUser(user);

      if (response.data) {
        console.log(response.data);
        navigate("../users", { replace: true });
        alert("Usuario agregado");
      }

      setIsSaving(false);
    } catch (err) {
      console.log(err);

      setIsSaving(false);
      alert("Hubo un error al guardar el usuario");
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: submitHandler,
    validateOnMount: true,
    validationSchema: validationSchema,
  });

  const options = [
    { value: UserRole.operator, description: "operador" },
    { value: UserRole.readOnly, description: "solo lectura" },
  ];

  return (
    <Form onSubmit={formik.handleSubmit}>
      <FormInput
        label="Nombre de usuario"
        textTransform="lowercase"
        {...formik.getFieldProps("username")}
        error={formik.touched.username ? formik.errors.username : ""}
      />

      <FormSelect
        label="Tipo"
        {...formik.getFieldProps("role")}
        error={formik.touched.role ? formik.errors.role : ""}
        options={options}
      />

      <FormInput
        label="Password"
        type="password"
        {...formik.getFieldProps("password")}
        error={formik.touched.password ? formik.errors.password : ""}
      />
      <FormInput
        label="Confirma password"
        type="password"
        {...formik.getFieldProps("passwordConfirmation")}
        error={
          formik.touched.passwordConfirmation
            ? formik.errors.passwordConfirmation
            : ""
        }
      />
      <FormActions>
        <Button primary type="submit" disabled={!formik.isValid || isSaving}>
          Guardar
        </Button>
      </FormActions>
    </Form>
  );
};

export default NewUser;
