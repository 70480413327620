import styled from "styled-components";

export const PaginationButton = styled.button`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
  border: none;
  background-color: ${({ theme }) => theme.colors.grayLight};
  color: ${({ theme }) => theme.colors.primary};

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
    color: #fff;
  }
`;

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
`;

export const PaginationButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const PaginationInput = styled.input`
  background-color: ${({ theme }) => theme.colors.grayLight};
  outline: none;
  border: 1px solid ${({ theme }) => theme.colors.grayMedium};
  padding: 4px;
  width: 100px;
  border-radius: 4px;
`;
