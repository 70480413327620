import styled from "styled-components";

interface ButtonProps {
  primary?: boolean;
  hasIcon?: boolean;
  danger?: boolean;
}

const Button = styled.button<ButtonProps>`
  padding: 6px 12px;
  cursor: pointer;
  border-radius: 4px;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  color: ${({ theme, primary, danger }) =>
    primary || danger ? "#fff" : theme.colors.primary};
  border: ${({ theme, danger }) =>
    "1px solid " + (danger ? theme.colors.red : theme.colors.primary)};

  background-color: ${({ theme, primary, danger }) => {
    if (primary) {
      return theme.colors.primary;
    } else if (danger) {
      return theme.colors.red;
    } else {
      return "#fff";
    }
  }};

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  ${({ hasIcon }) =>
    hasIcon &&
    ` 
    display:flex;
    align-items:center;
    gap:8px;
    `}
`;

export default Button;
