export enum UserRole {
  admin,
  operator,
  readOnly,
}

export interface User {
  id: string;
  username: string;
  dateAdded: Date;
  isActive: boolean;
  role: UserRole;
}

export interface UserCreate {
  username: string;
  password: string;
  // passwordConfirmation: string;
  role: UserRole;
}

export interface UserEdit {
  isActive: boolean;
}
