import styled from "styled-components";

interface FlexProps {
  direction?: "column" | "column-reverse" | "row" | "row-reverse";
  gap?: number;
}

const Flex = styled.div<FlexProps>`
  display: flex;
  flex-direction: ${({ direction: flexDirection }) =>
    flexDirection ? flexDirection : "row"};
  gap: ${({ gap }) => (gap ? gap + "px" : "0")};
  ////////////////////////////////////////////
  justify-content: center;
  align-items: center;
`;
export default Flex;
