import styled from "styled-components";

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 100%;
`;

export const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 18px;
`;

export const PageHeaderActions = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const PageContent = styled.div`
  padding: 12px 18px;
  display: flex;
  flex-direction: column;
  height: 100%;
`;
