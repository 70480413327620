import * as yup from "yup";

const requiredMessage = "Este campo es requerido";

export default yup.object().shape({
  username: yup
    .string()
    .required(requiredMessage)
    .min(8, "El nombre de usuario deber ser de al menos 8 caracteres")
    .max(20, "El nombre de usuario deber tener máximo 20 caracteres"),
  password: yup
    .string()
    .required(requiredMessage)
    .min(8, "La contraseña debe ser de al menos 8 caracteres")
    .max(16, "La contraseña debe tener máximo 16 caracteres")
    .matches(
      new RegExp("[A-Z]+"),
      "La contraseña debe contener al menos una letra mayuscula"
    )
    .matches(
      new RegExp("[a-z]+"),
      "La contraseña debe contener al menos una letra minuscula"
    )
    .matches(
      new RegExp("[0-9]+"),
      "La contraseña debe contener al menos una numero"
    )
    .matches(
      new RegExp("[\\!\\?\\*\\.]+"),
      "La contraseña debe contener al menos un caracter especial (!, ?, *, .)"
    ),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "Las contraseñas no coinciden"),
});
