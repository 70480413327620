import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./states/auth/auth-slice";
import { errorSlice } from "./states/error/error-slice";

export const store = configureStore({
  reducer: { error: errorSlice.reducer, auth: authSlice.reducer },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
