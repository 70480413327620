import axios from "./axios-api";
import { RecordCreate, RecordSearch } from "../interfaces/record.model";

export const getRecords = () => {
  return axios.get<any>(`/places`);
};

export const getRecordById = (id: string) => {
  return axios.get<any>(`/places/${id}`);
};

export const updateRecord = (id: string, record: RecordCreate) => {
  return axios.put<any>(`/places/${id}`, record);
};

export const createRecord = (record: RecordCreate) => {
  return axios.post<any>(`/places`, record);
};

export const searchRecords = (recordSearch: RecordSearch) => {
  return axios.post<any>(`/places/search`, recordSearch);
};
