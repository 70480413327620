import { List, SignOut, X } from "phosphor-react";
import { FC, useState } from "react";
import Button from "../Button/Button";
import { SidebarProps } from "./interfaces/sidebar-props";

import {
  Footer,
  MenuContainer,
  SidebarContainer,
  SidebarContent,
  StyledNavLink,
  StyledSidebar,
  ToggleButton,
} from "./styles/Sidebar.Styled";

const Sidebar: FC<SidebarProps> = (props: SidebarProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleHandle = () => {
    setIsExpanded((prevState) => {
      return !prevState;
    });
  };

  return (
    <SidebarContainer>
      <ToggleButton onClick={toggleHandle} isSidebarExpanded={isExpanded}>
        {isExpanded ? <X /> : <List />}
      </ToggleButton>
      <StyledSidebar isExpanded={isExpanded}>
        <SidebarContent isExpanded={isExpanded}>
          <MenuContainer>
            <div>
              {props.links.map((link, index) => {
                return (
                  <StyledNavLink key={index} to={link.path}>
                    {link.icon}
                    <span>{link.name}</span>
                  </StyledNavLink>
                );
              })}
            </div>
            <Footer>
              <Button primary onClick={props.onSignOut}>
                <SignOut />
                Salir
              </Button>
            </Footer>
          </MenuContainer>
        </SidebarContent>
      </StyledSidebar>
    </SidebarContainer>
  );
};

export default Sidebar;
