import axios from "axios";

const baseUrl =
  process.env.NODE_ENV === "development"
    ? "https://localhost:5000/api"
    : "https://api.rzreg.com/api";

export const login = (username: string, password: string) => {
  return axios.post<any>(
    //"http://api.rzreg.com/login"
    `${baseUrl}/login`,
    {
      username,
      password,
    }
  );
};
export const refreshToken = (refreshToken: string) => {
  return axios.post<any>(
    //"http://api.rzreg.com/refresh"
    `${baseUrl}/refresh`,
    {
      refreshToken,
    }
  );
};
