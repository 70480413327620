import { useAppDispatch } from "../../hooks/redux";
import { login } from "../../redux/states/auth/auth-actions";
import Button from "../../components/Button/Button";
import styles from "./Login.module.css";
import { useState } from "react";
import { Form, FormActions } from "../Form/Form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FormInput from "../../components/FormInput/FormInput";
import { useFormik } from "formik";
import validationSchema from "./validationSchema";
import Card from "../../components/Card/Card";
import Flex from "../../components/Flex/Flex";
import StyledLogin from "./StyledLogin";
import { LoginTitle } from "./StyledLogin";

interface FormValues {
  username: string;
  password: string;
}

const Login = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const initialValues: FormValues = {
    username: "",
    password: "",
  };

  const submitHandler = async (values: FormValues) => {
    try {
      setIsLoading(true);
      await dispatch(login(values.username, values.password));
      setIsLoading(false);
    } catch (err: any) {
      let message = "Ocurrio un error. Vuelva a intentarlo.";

      if (err.response && err.response.status === 409) {
        message = err.response.data.message;
      }

      toast(message, {
        type: "error",
      });
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: submitHandler,
    validateOnMount: true,
    validationSchema: validationSchema,
  });

  return (
    <StyledLogin>
      <Flex direction="column">
        <LoginTitle>Iniciar Sesión</LoginTitle>
        <Card padding={32}>
          <Form onSubmit={formik.handleSubmit}>
            <FormInput label="Usuario" {...formik.getFieldProps("username")} />
            <FormInput
              label="Contraseña"
              type="password"
              {...formik.getFieldProps("password")}
            />
            <FormActions>
              <Button
                primary
                disabled={!formik.isValid || isLoading}
                type="submit"
              >
                Iniciar Sesión
              </Button>
            </FormActions>
          </Form>
        </Card>
      </Flex>
    </StyledLogin>
  );
};

export default Login;
