import { createGlobalStyle } from "styled-components";

export interface ThemeType {
  colors: {
    accent: string;
    primary: string;
    accentDark: string;
    stripped: string;
    grayLight: string;
    grayMedium: string;
    red: string;
  };
  screens: {
    small: string;
    medium: string;
  };
}

const GlobalStyles = createGlobalStyle<{ theme: ThemeType }>`
    @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

*{
    box-sizing:border-box;
    margin:0;
    padding: 0;
}

body{
  background-color: #f1f3f5;
}
`;

export default GlobalStyles;
