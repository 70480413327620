import axios from "axios";
import { refreshToken } from "./account.service";
import { toast } from "react-toastify";

const instance = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "https://localhost:5000/api"
      : "https://api.rzreg.com/api",

  // baseURL: "http://api.rzreg.com/api",
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
});

// const setupStore = (store: Store) => {
instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    config.headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => response,
  async (err) => {
    const config = err.config;

    if (err.response.status === 401) {
      if (!config._retry) {
        try {
          config._retry = true;
          const token = localStorage.getItem("refresh_token") ?? "";
          const response = await refreshToken(token);

          axios.defaults.headers.common["Authorization"] =
            "Bearer " + response.data.token;
          localStorage.setItem("token", response.data.token);
          return instance(config);
        } catch (error2) {
          localStorage.removeItem("token");
          window.location.href = "/";
        }
      } else {
        localStorage.removeItem("token");
        window.location.href = "/";
        return;
      }
    } else {
      let message = "Ocurrio un error. Intentelo de nuevo";

      if (err.response.status === 409) {
        message = err.response.data.message;
      }
      toast(message, {
        type: "error",
      });

      return Promise.reject(err);
    }
  }

  // if (err.response.status === 401) {
  //   // localStorage.removeItem("token");
  //   // window.location.href = "/";
  //   return;
  // } else if (err.respose.status === 403 && !originalRequest._retry) {
  //   originalRequest._retry = true;
  //   const token = localStorage.getItem("refresh_token") ?? "";
  //   const accessToken = await refreshToken(token);
  //   axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
  //   return instance(originalRequest);
  // } else {
  //   const message =
  //     err.response.data.error || err.response.statusText || "Unknown error";

  //   // store.dispatch(errorActions.showError({ title: "Error", message }));
  //   return Promise.reject(err);
  // }
);
// };

// export { setupStore };
export default instance;
